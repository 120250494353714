<template>
	<div>
		<Home />
	</div>
</template>

<script>
	import Home from "./views/home.vue"

	export default {
		name: "App",
		components: {
			Home,
		},

		mounted() {
			this.initCNZZ()
		},

		methods: {
			initCNZZ() {
				// 添加脚本
				const script = document.createElement('script')
				script.src = 'https://s4.cnzz.com/z_stat.php?id=1280670952&web_id=1280670952'
				script.id = 'cnzz'
				document.body.appendChild(script)
			}
		},

		watch: {
			// 路由变化时发送统计
			'$route': {
				handler() {
					setTimeout(() => {
						if (window._czc) {
							let location = window.location
							let contentUrl = location.pathname + location.hash
							let refererUrl = '/'
							window._czc.push(['_setAutoPageview', false])
							window._czc.push(['_trackPageview', contentUrl, refererUrl])
						}
					}, 300)
				},
				// 页面第一次加载的时候就执行
				immediate: true
			}
		}

	};
</script>

<style>
	@import "./assets/css/base.css";

	* {
		padding: 0;
		margin: 0;
	}
</style>
