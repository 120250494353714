<template>
  <div class="vertical-display1">
    <div class="a">
      <div class="aimg">
        <img src="~assets/img/home/Logo@2x.png" alt />
      </div>

      <div class="Navigation">
        <a class="NavigationItem" href="#shouye">首页</a>
        <a class="NavigationItem" href="#yuancheng">飞术Fem surgical</a>
        <a class="NavigationItem" href="#about-us">关于我们</a>
        <a class="NavigationItem" href="#Contact-information">联系方式</a>
      </div>
    </div>

    <div class="b" id="shouye">
      <div>
        <img id="img" src="~assets/img/home/website-background.png" alt />
      </div>
      <div class="name">
        <div class="jushu">矩数</div>
        <div class="jushu-en">MATRIGITAL</div>
      </div>
    </div>

    <div class="c">
      <div class="assist-div" id="yuancheng"></div>
      <div class="c1">
        <div class="item-tit">远程示教</div>
        <div class="item-tit-en">REMOTE ENLIGHTENMENT</div>
      </div>
      <div class="vertical-display-item1">
        <div class="cItem">
          <img src="~assets/img/home/teaching-consultation.png" />
          <div class="daijiao">
            <p class="txt1">带教会诊</p>
            <p class="txt2">TEACHING&CONSULATION</p>
            <p class="txt3">手术远程实时指导</p>
            <p class="txt3">带教</p>
            <p class="txt3">高效远程会诊</p>
          </div>
        </div>
        <div class="cItem" style="margin: 0 1.875vw">
          <img src="~assets/img/home/timely-communication.png" />
          <div class="jishi">
            <p class="txt1">即时交流</p>
            <p class="txt2">ONLINE COMMUNICATION</p>
            <p class="txt3">通过手机、电脑、PAD调阅进行中的手术</p>
            <p class="txt3">随时随地与术者及专家进行视频交流</p>
          </div>
        </div>
        <div class="cItem">
          <img src="~assets/img/home/image4.png" />
          <div class="jingzhun">
            <p class="txt1">精准指导</p>
            <p class="txt2">PRECISE GUIDANCE</p>
            <p class="txt3">创新手术画板功能</p>
            <p class="txt3">借助内外科手术器械</p>
            <p class="txt3">精准表达手术过程</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'levelDisplay'
}
</script>



<style>
.vertical-display1 {
  margin: 0 auto;
  color: #ffffff;
}

.a {
  position: fixed;
  top: 0;
  left: 0;
  height: 3.54vw;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 3.12vw;
  z-index: 1;
  background: #010202;
}

.aimg {
  width: 9.11vw;
  height: 3.54vw;
}

.aimg img {
  width: 100%;
  height: 100%;
}

.Navigation {
  display: flex;
  align-items: center;
}

.NavigationItem {
  font-family: PingFang SC, PingFang SC-Semibold;
  font-size: 0.73vw;
  font-weight: 600;
  margin-right: 3.12vw;
  cursor: pointer;
  color: white;
}

.b {
  margin: 0 auto;
  margin-bottom: 7.8125vw;
  position: relative;
}

.about-tit {
  height: 2.91vw;
  line-height: 2.91vw;
  font-size: 2.08vw;
  font-weight: 600;
  color: #ffffff;
}

.about-div2 {
  margin-top: 0;
}

.b img {
  width: 100%;
}

.name {
  position: absolute;
  left: 20%;
  top: 50%;
  transform: translateY(-50%);
}

.jushu {
  font-size: 3.54vw;
  font-weight: 600;
}

.jushu-en {
  font-size: 1.25vw;
  font-weight: 500;
}

.c1 {
  text-align: center;
}

.vertical-display-item1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cItem img {
  width: 19.53vw;
  height: 14.58vw;
}

.daijiao {
  text-align: center;
  margin-top: 3.12vw;
  width: 9.58vw;
  height: 8.59vw;
}

.jishi {
  text-align: center;
  margin-top: 3.12vw;
  width: 11.45vw;
  height: 8.59vw;
}

.jingzhun {
  text-align: center;
  margin-top: 3.12vw;
  width: 9.58vw;
  height: 8.59vw;
}

.txt1 {
  font-weight: 600;
  font-size: 1.25vw;
}

.txt2 {
  margin-top: 0.31vw;
  margin-bottom: 1.56vw;
  font-weight: 500;
  font-size: 0.72vw;
  color: #949494;
}

.txt3 {
  margin-bottom: 0.625vw;
  font-weight: 600;
  font-size: 0.625vw;
  color: #dddddd;
}
.assist-div {
  height: 0.05vw;
  margin-bottom: 3.54vw;
}
</style>
