<template>
  <div class="footer">
    <!--上定位辅助节点-->
    <div class="assist-div" id="about-us"></div>
    <div class="about-div1">
      <p class="about-tit">关于我们</p>
      <p class="about-tit-eng">ABOUT US</p>
      <div class="about-content">
        <p class="contact-tit" style="max-width: 62.5vw; line-height: 2.08vw;">
          矩数(Matrigital)致力于不断创新和开发可扩展的智能数字技术，将最佳的信息化、互联化、智能化产品体验带入医疗行业。我们自主研发的基于云的飞术(Femsurgical)系统使处在信息孤岛上的医疗信息能够基于5G网络以多种形式进行传播和互动，并进行实时协作。飞术是用智能信息技术下沉优质医疗资源，提升区域医疗水平的均等化水平，解决医疗资源总量不足、分布不均衡难题的最佳解决方案。
        </p>
      </div>
    </div>
    <!--上定位辅助节点-->
    <div class="assist-div" id="Contact-information"></div>
    <div class="about-div2">
      <p class="about-tit">联系方式</p>
      <p class="about-tit-eng">CONTACT</p>
      <div class="contact">
        <p class="contact-tit">电话</p>
        <p class="contact-con">4001661692</p>
      </div>
      <div class="contact">
        <p class="contact-tit">邮箱</p>
        <p class="contact-con">cheng.xu@matrigital.com</p>
      </div>
      <div class="contact">
        <p class="contact-tit">地址</p>
        <p class="contact-con">
          西安市雁塔区南二环西段64号凯德FED写字楼（东塔）9楼903室
        </p>
      </div>
      <div class="contact">
        <p class="contact-tit">公众号</p>
        <img src="~assets/img/home/QR-Code.jpg" alt />
      </div>
    </div>

    <div class="beian">
      <p style="margin-bottom: 3px;">
        Copyright ©️ 2021-2024 矩数(西安)智能科技有限公司
      </p>
      <p>
        <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index"
          >陕ICP备2021016151号-1</a
        >
        |
        <a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch"
          >陕公网安备 61011302001366号</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
p {
  margin: 0 auto;
}

.footer {
  width: 100%;
  text-align: center;
  padding-top: 4.94vw;
}

.about-div1 {
}

.about-tit {
  height: 2.91vw;
  line-height: 2.91vw;
  font-size: 2.08vw;
  font-weight: 600;
  color: #ffffff;
}

.about-tit-eng {
  height: 1.77vw;
  line-height: 1.77vw;
  font-size: 1.25vw;
  font-weight: 500;
  color: #949494;
}

.about-content {
  margin: 0 auto;
  margin-top: 2.6vw;
  text-align: left;
  text-indent: 3.75vw;
  width: 72.91vw;
  height: 26.04vw;
}

.contact-tit {
  height: 1.77vw;
  line-height: 1.77vw;
  font-size: 1.25vw;
  font-weight: 600;
  color: #ffffff;
  margin-top: 0.625vw;
}

.contact-con {
  height: 1.04vw;
  line-height: 1.04vw;
  font-size: 0.72vw;
  font-weight: 600;
  color: #ffffff;
}

.contact {
  margin-top: 2.29vw;
}

.contact img {
  width: 10.41vw;
  height: 10.41vw;
}

.beian {
  width: 100%;
  font-size: 0.9375vw;
  font-family: Roboto, Roboto-Regular;
  font-weight: 500;
  text-align: CENTER;
  color: #ffffff;
  margin-top: 5.2vw;
  border-top: 0.052vw solid rgba(255, 255, 255, 0.5);
  padding: 2.6vw 0;
}

.beian a {
  color: #ffffff !important;
  user-select: text;
}
</style>
