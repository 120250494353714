<template>
  <div class="content">
    <div class="vertical-display">
      <div class="vertical-display-item">
        <div class="item-tit">一键直播</div>
        <div class="item-tit-en">INSTANT LIVE</div>
        <div class="item-con">
          <p>
            基于
            <span>5G</span>的高速即时会议功能
          </p>
          <p>
            让来自手术室、内镜室、会议室的线上会议
            <span>飞速便捷</span>
          </p>
        </div>
        <img src="~assets/img/home/image5.png" alt />
      </div>
      <div class="vertical-display-item">
        <div class="item-tit">手术云盘</div>
        <div class="item-tit-en">SURGERY CLOUD</div>
        <div class="item-con">
          <div class="item-con-p">
            <div class="rad"></div>
            <p>手术视频资料云上自动保存，永久安全</p>
          </div>
          <div class="item-con-p">
            <div class="rad"></div>
            <p>云端智能剪辑</p>
          </div>
        </div>
        <img src="~assets/img/home/image6.png" alt />
      </div>
      <div class="vertical-display-item">
        <div class="item-tit">术视</div>
        <div class="item-tit-en">NATUBE PLATFORM</div>
        <div class="item-con">
          <p>
            通过
            <span>术视NATUBE平台</span>传播飞术直播、转播内容
          </p>
          <p>软件结合硬件</p>
          <p>保证内容的最大量级输出</p>
        </div>
        <img class="mockuper" src="~assets/img/home/mockuper.png" alt />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerDisplay'
}
</script>

<style>
.content {
  color: #ffffff;
}
.vertical-display {
  width: 62.34vw;
  padding: 7.8125vw 0;
  margin: 0 auto;
}
.vertical-display-item {
  margin-bottom: 9.63vw;
  text-align: center;
}
.item-tit {
  font-size: 2.08vw;
  font-weight: 600;
}
.item-tit-en {
  font-size: 1.25vw;
  font-weight: 500;
  margin: 0.78125vw 0 2.86vw 0;
  color: #949494;
}
.item-con {
  font-size: 0.9375vw;
  font-weight: 600;
  margin-bottom: 3.64vw;
}
.item-con-p {
  width: 18.75vw;
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.item-con p {
  margin: 0.78125vw 0;
  color: #dddddd;
}
.item-con span {
  font-size: 1.875vw;
}
.vertical-display-item img {
  width: 47.96vw;
}
.rad {
  width: 0.625vw;
  height: 0.625vw;
  background: #ffffff;
  border-radius: 50%;
  margin-right: 1.979vw;
}
.mockuper {
  width: 57.29vw !important;
}
</style>