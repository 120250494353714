<template>
  <div id="home">
    <Head />
    <ver-display />
    <div class="pro">
      <img src="~assets/img/home/image9.png" alt />
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '../components/footer'
import VerDisplay from '../components/ver-display'
import Head from '../components/head'

export default {
  name: 'Home',
  components: {
    Footer,
    VerDisplay,
    Head
  }
}
</script>

<style>
#home {
  background: #111111;
}
.pro {
  height: 43.38vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
}
.pro img {
  width: 32.24vw;
  height: 26.35vw;
}
</style>